<template>
  <div class="attendance-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Lịch sử làm việc</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            v-if="getUserRole().name !== 'medical_facility'"
            :md="resolveFilterLayout()"
          >
            <select-facility
              v-model="medicalFacility"
              :label="null"
            />
          </b-col>
          <b-col :md="resolveFilterLayout()">
            <select-doctor
              v-model="doctor"
              :medical-facility="medicalFacility"
            />
          </b-col>
          <b-col :md="resolveFilterLayout()">
            <select-date
              v-model="startTime"
              placeholder="Từ ngày"
            />
          </b-col>
          <b-col :md="resolveFilterLayout()">
            <select-date
              v-model="endTime"
              placeholder="Đến ngày"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"

              :pagination-options="{
                enabled: true,
                perPage: serverParams.maxResultCount,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-apartment-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <span v-else-if="props.column.field === 'doctor.full_name'">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        rounded
                        :src="`https://api.vtelehealth.vn:8443/media/view?token=${encodeURIComponent(props.row.doctor.avatar)}-100.webp`"
                        size="48"
                      />
                    </template>
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ props.row.doctor? props.row.doctor.full_name: '' }}
                    </b-link>
                    <small class="text-muted">{{ props.row.doctor ? props.row.doctor.phone: '' }}</small>
                  </b-media>
                </span>

                <span v-else-if="props.column.field === 'number_meetings'">
                  <!-- <b-link
                    v-b-tooltip.hover.v-primary
                    v-b-modal.modal-history
                    title="Xem phiên khám"
                    @click="onEditItem(props.row)"
                  >{{ props.row.meetings.length }}</b-link> -->
                </span>

                <span v-else-if="props.column.field === 'online_time'">
                  {{ resolveOnlineTime(props.row) }}
                </span>

                <span v-else-if="props.column.field === 'start_time'">
                  {{ resolveTime(props.row.start_time) }}
                </span>

                <span v-else-if="props.column.field === 'end_time'">
                  {{ resolveTime(props.row.end_time) }}
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.maxResultCount"
                      :options="['10', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.maxResultCount"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <history-modal :work-log-id="item && item._id ? item._id : ''" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BAvatar,
  BMedia,
  VBTooltip,
  VBModal,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
// eslint-disable-next-line import/no-cycle
import SelectDoctor from '@/views/components/SelectDoctor.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import HistoryModal from '@/views/operation/history/modal/HistoryModal.vue';
import moment from 'moment';
import { getUserRole } from '@/auth/utils';
import SelectFacility from '@/views/components/SelectFacility.vue';
import useAttendanceList from './useAttendanceList';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BAvatar,
    BFormSelect,
    BSpinner,
    BMedia,
    VueGoodTable,
    SelectDoctor,
    SelectDate,
    HistoryModal,
    SelectFacility,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      doctor,
      medicalFacility,
      startTime,
      endTime,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,

      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveFamilyDoctorVariant,
      resolveOnlineTime,
      resolveTime,
      resolveFilterLayout,
    } = useAttendanceList();

    return {
      analytics,
      item,
      columns,
      rows,
      doctor,
      medicalFacility,
      startTime,
      endTime,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,

      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveFamilyDoctorVariant,
      resolveOnlineTime,
      resolveTime,
      getUserRole,
      resolveFilterLayout,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {

    parseDate(dateString) {
      return moment(dateString).format("DD-MM-YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.attendance-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 150px !important;
            }
            &:nth-child(3) {
               width: 150px !important;
               min-width: 150px !important;
            }
            &:nth-child(4) {
               width: 150px !important;
               min-width: 150px !important;
            }
            &:nth-child(5) {
               width: 180px !important;
               min-width: 180px !important;
            }
            &:nth-child(6) {
               width: 120px !important;
               min-width: 120px !important;
            }
          }
        }
      }
    }
}
</style>
